import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Job } from './download-manager-popup/Job';
import { ConnectExportService } from '@esders/connect-api';

@Injectable({
	providedIn: 'root'
})
export class DownloadManagerService {
	env = environment;

	private downloadsFinished: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private jobIds: BehaviorSubject<Job[]> = new BehaviorSubject<Job[]>([]);
	public showDownloadWindow: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private _connectExportService : ConnectExportService
	) {
		setInterval(() => {
			this.updateJobs();
		}, 1000);

		window.addEventListener("beforeunload", event => this.onWindowClose(event));
	}

	onWindowClose(event: any): void {
		let jobs = this.jobIds.value;
		let unfinishedJobs = jobs.filter(x => x.state == 0 || x.state == 1);

		if (unfinishedJobs.length > 0) {
			event.preventDefault();
			event.returnValue = false;
		}
	}

	/**
	 * @returns Liefert ein observable zurück, damit die view regelmäßig aktualisiert werden kann.
	 */
	public getJobIds(): Observable<Job[]> {
		return this.jobIds.asObservable();
	}

	/**
	 * @returns Liefert ein observable zurück, damit die view regelmäßig aktualisiert werden kann.
	 */
	public allDownloadsFinished(): Observable<boolean> {
		return this.downloadsFinished.asObservable();
	}

	/**
	 * @brief Legt einen Downloadjob für Messungen an.
	 */
	public downloadMeasurements(page: number, pageSize: number, lang: string, search: any): Promise<boolean> {
		return new Promise(async (promise, reject) => {
			await lastValueFrom(this._connectExportService.createMeasExport(page, pageSize,lang, search))
			.then((res: any) => {
				this.addJob({ jobId: res.jobId, errorMessage: res.errorMessage, state: this.stateToInt(res.state), type: "t_measurements", orderId: "", token: "" });
				this.showDownloadWindow.emit(true);
			})
			.catch(err => reject(err));

			promise(true);
		});
	}

	/**
	 * @brief Legt einen Downloadjob für Devicetests an.
	 */
	public downloadDevicetests(page: number, pageSize: number, lang: string, search: any): Promise<boolean> {
		return new Promise(async (promise, reject) => {
			await lastValueFrom(this._connectExportService.createDeviceTestExport(page, pageSize, lang, search))
			.then((res: any) => {
				this.addJob({ jobId: res.jobId, errorMessage: res.errorMessage, state: this.stateToInt(res.state), type: "t_devicetests", orderId: "", token: "" });
				this.showDownloadWindow.emit(true);
			})
			.catch(err => reject(err));

			promise(true);
		});
	}

	/**
	 * @brief Legt einen Downloadjob für Zertifikate an.
	 */
	public downloadOrderCertificates(orderIdOrNumber: string, lang: string, token: string = ""): Promise<boolean> {
		return new Promise(async promise => {
			let request$ = this._connectExportService.createOrderCertificateExport(orderIdOrNumber, lang);
			if (token !== "")
				request$ = this._connectExportService.createOrderCertificateExportByOrderToken(orderIdOrNumber, token, lang);

			await lastValueFrom(request$)
			.then((res: any) => {
				this.addJob({ jobId: res.jobId, errorMessage: res.errorMessage, state: this.stateToInt(res.state), type: "t_certificates", orderId: orderIdOrNumber, token: token });
				this.showDownloadWindow.emit(true);
			});

			promise(true);
		});
	}

	/**
	 * @brief Legt einen Downloadjob für Zertifikate an.
	 */
	public downloadServiceCertificates(serviceId: string, lang: string): Promise<boolean> {
		return new Promise(async promise => {
			await lastValueFrom(this._connectExportService.createServiceDeviceCertificateExport(serviceId, lang))
			.then((res: any) => {
				this.addJob({ jobId: res.jobId, errorMessage: res.errorMessage, state: this.stateToInt(res.state), type: "t_certificates", orderId: "", token: "" });
				this.showDownloadWindow.emit(true);
			});

			promise(true);
		});
	}

	/**
	 * @brief Fügt einen Job zur Downloadliste hinzu.
	 * @param job Job zum hinzufügen
	 */
	private addJob(job: Job) {
		let jobs = this.jobIds.value;
		jobs.push(job);

		this.downloadsFinished.next(false);
		this.jobIds.next(jobs);
	}

	/**
	 * @brief Polled die Jobs in der Liste.
	 */
	private updateJobs() {
		let jobs = this.jobIds.value;

		jobs.forEach(job => {
			if (job.state != 2 && job.state != 3) {
				let request$;

				if (job.token !== "")
					request$ = this._connectExportService.getExportState(job.jobId.toString(), job.orderId, job.token);
				else
					request$ = this._connectExportService.getExportState(job.jobId.toString());

				request$
				.pipe(take(1))
				.subscribe(res => {
					job.jobId = res.jobId;
					job.errorMessage = res.errorMessage;
					job.state = this.stateToInt(res.state);
				});
			}
		});

		this.downloadsFinished.next(jobs.filter(x => x.state == 0 || x.state == 1).length == 0);
		this.jobIds.next(jobs);
	}

	private stateToInt(sate : string) {
		switch (sate) {
			case "Waiting":
				return 0;
			case "Working":
				return 1;
			case "Ready":
				return 2;
			case "Error":
				return 3;
			default:
				return 0;
		}
	}

	// GET /{{jobid}}/state
	// GET /{{jobid}}/stream
}
