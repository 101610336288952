import { Injectable } from '@angular/core';
import { CardPoint } from '@esders/connect-api';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CardConfigurationService {
	private cardConfiguration: Map<number, CardPoint[]> = new Map<number, CardPoint[]>();
	env = environment;

	constructor() { }

	public getCardConfiguration(): Map<number, CardPoint[]> {
		return this.cardConfiguration;
	}

	public setCardConfiguration(value: Map<number, CardPoint[]>) {
		this.cardConfiguration = value;
	}
}
