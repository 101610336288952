import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslationHelperService } from '@esders/utilities';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, Observable, finalize, lastValueFrom, take, tap } from 'rxjs';
import { DownloadManagerService } from 'src/app/shared/download-manager/download-manager.service';
import { environment } from 'src/environments/environment';
import { CardConfigurationService } from './card-configuration.service';
import { CompareService } from './compare.service';
import { DetailsearchService } from './detailsearch.service';
import { Page } from './filterStorage.service';
import { CardPoint, DetailedSearchObject, ElasticDeviceTestService, ElasticMeasService, FullElasticIndex, InitialElasticMeas, Menu, MultiReportService } from '@esders/connect-api';
import { LocationsSearchObject } from 'src/app/shared/models/DetailedSearchObject';
import { ErrorHandlerService, GuiOperatorsService } from '@esders/gui';
import { AuthService } from '@esders/authentificate';
import { AnalyticsService, TrackEventDownload } from '@esders/analytics';

@Injectable({
	providedIn: 'root'
})
export class MeasurementService {
	env = environment;

	private _pageType: Page | null = null;
	private _endpoint: string = "";

	public detailSearch: BehaviorSubject<DetailedSearchObject> = new BehaviorSubject<DetailedSearchObject>({} as DetailedSearchObject);
	public elasticMeasurements: BehaviorSubject<FullElasticIndex[]> = new BehaviorSubject<FullElasticIndex[]>([]);

	public page: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public maxpage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);
	public oldPageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);

	public isFilled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public downloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public menu: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);

	public locationSearch: BehaviorSubject<LocationsSearchObject> = new BehaviorSubject<LocationsSearchObject>({} as LocationsSearchObject);
	public locationMeasurements: BehaviorSubject<FullElasticIndex[]> = new BehaviorSubject<FullElasticIndex[]>([]);

	public reloadMap: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private _detailsSearchService: DetailsearchService,
		private _http: HttpClient,
		private _authService: AuthService,
		private _cardService: CardConfigurationService,
		private _comparer: CompareService,
		private _downloadManager: DownloadManagerService,
		private _translate: TranslateService,
		private _translateHelper: TranslationHelperService,
		private _errorHandlerService: ErrorHandlerService,
		@Inject(LOCALE_ID) private locale: string,
		private _analyticsService: AnalyticsService,
		private _rxjs: GuiOperatorsService,
		private _multiReport: MultiReportService,
		private _elasticMeasService: ElasticMeasService,
		private _elasticDeviceTestService: ElasticDeviceTestService,
	) { }

	public deleteMeasurement(measId: string): Observable<any> {
		var result$: Observable<any> = EMPTY;
		switch (this._pageType) {
			case Page.DEVICE_TEST: {
				result$ = this._elasticDeviceTestService.deleteDeviceTest(measId).pipe(this._rxjs.catchHttpErrors());
			} break;

			case Page.MEAS_LIST: {
				result$ = this._elasticMeasService.deleteMeasurement(measId).pipe(this._rxjs.catchHttpErrors());
			} break;
		}

		return result$.pipe(tap(() => this.removeMeasurement(measId)), take(1));
	}

	public reactivateMeasurement(measId: string): Observable<any> {
		var result$: Observable<any> = EMPTY;

		switch (this._pageType) {
			case Page.DEVICE_TEST: {
				result$ = this._elasticDeviceTestService.reactivateDeviceTest(measId).pipe(this._rxjs.catchHttpErrors());
			} break;

			case Page.MEAS_LIST: {
				result$ = this._elasticMeasService.reactivateMeasurement(measId).pipe(this._rxjs.catchHttpErrors());
			} break;
		}

		return result$.pipe(tap(() => this.removeMeasurement(measId)), take(1));
	}

	public removeMeasurement(measId: string) {
		var meas = this.elasticMeasurements.value;
		this.elasticMeasurements.next(meas.filter(x => x.id != measId));

		meas = this.locationMeasurements.value;
		this.locationMeasurements.next(meas.filter(x => x.id != measId));
	}

	public clear() {
		var val = { details: [] } as DetailedSearchObject;
		(val as any).menuId = -1;

		this.detailSearch.next(val);
		this._detailsSearchService.setFilter(this.detailSearch.value);
	}

	/**
	 * @brief Wird immer beim wechsel auf eine Measlist aufgerufen.
	 */
	public initialize(pageType: Page, endpoint: string) {
		this._pageType = pageType;
		this._endpoint = endpoint;
		this.elasticMeasurements.next([]);

		this.updateMesurements(0, "init");
	}

	/**
	 * @brief Aktualisiert die Messungsliste anhand der Suchparameter.
	 */
	public updateMesurements(setpage: number = 0, endUri: string = '') {
		this.isFilled.next(false);
		this.elasticMeasurements.next([]);

		if (endUri !== "" && this._pageType)
			this.detailSearch.next(this._detailsSearchService.getDetailedSearch(this._pageType));

		if (this.oldPageSize.value != this.pageSize.value) {
			this.page.next(0);
			this.oldPageSize.next(this.pageSize.value);
		}
		else
			this.page.next(setpage);

		let startDateModified = false;
		let endDateModified = false;

		this.isLoading.next(true);
		if (this.detailSearch.value.startDate == null) {
			this.detailSearch.value.startDate = "01.01.1990";
			startDateModified = true;
		}
		if (this.detailSearch.value.endDate == null) {
			this.detailSearch.value.endDate = new Date().toDateString();
			endDateModified = true;
		}

		if (this.detailSearch.value.menuId == null) {
			this.detailSearch.value.menuId = '-1';
		}

		let uri = this.env.connectGwUri + "/" + this._endpoint + "/" + this.page.value + "/" + this.pageSize.value;
		if (endUri !== "") {
			uri = this.env.connectGwUri + "/" + this._endpoint + "/" + this.page.value + "/" + this.pageSize.value + "/" + endUri;
		}

		if (startDateModified)
			this.detailSearch.value.startDate = null;

		if (endDateModified)
			this.detailSearch.value.endDate = null;

		this._http
			.post<InitialElasticMeas>(uri, this.detailSearch.value,
				{
					headers: {
						"Authorization": "Bearer " + this._authService.getTokenValue()
					}
				})
			.subscribe((res: InitialElasticMeas) => {

				if (endUri !== "") {
					const tmp = new Map<number, CardPoint[]>();
					for (const [key, value] of Object.entries(res.cards)) {
						tmp.set(Number(key), value);
					}

					this._cardService.setCardConfiguration(tmp);
					this.menu.next(res.availableMenus);
					this._comparer.GetSearchParamsFromServer(res.searchParameter);
				}

				this.elasticMeasurements.next(res.indexes);
				this.maxpage.next(res.foundIndex);
				this.isLoading.next(false);
				this.reloadMap.emit(true);

				this.isFilled.next(this.menu.value.find(x => x.menuId.toString() == this.detailSearch.value.menuId).isMultiReport);
			});
	}

	/**
	 * @brief Aktualisiert die Kartendaten.
	 */
	public updateLocations(top: number, right: number, bottom: number, left: number) {
		this.isLoading.next(true);
		if (this.locationSearch.value.startDate == null) {
			this.locationSearch.value.startDate = "01.01.1990";
		}

		if (this.locationSearch.value.endDate == null) {
			this.locationSearch.value.endDate = new Date().toDateString();
		}

		this.locationSearch.value.details = this.detailSearch.value.details;
		this.locationSearch.value.MinLat = bottom;
		this.locationSearch.value.MaxLat = top;
		this.locationSearch.value.MinLong = left;
		this.locationSearch.value.MaxLong = right;

		this.locationSearch.value.menuId = this.detailSearch.value.menuId;
		this.locationSearch.value.serial = this.detailSearch.value.serial;
		this.locationSearch.value.archived = false;

		this._http
			.post<InitialElasticMeas>(this.env.connectGwUri + "/" + this._endpoint + "/location", this.locationSearch.value,
				{
					headers: {
						"Authorization": "Bearer " + this._authService.getTokenValue()
					}
				})
			.subscribe((res: InitialElasticMeas) => {
				this.locationMeasurements.next(res.indexes);
				this.isLoading.next(false);
			});
	}

	public async downloadMeasList() {
		let measurements = this.elasticMeasurements.getValue();
		let csv = "";

		csv += await lastValueFrom(this._translateHelper.translate("t_date")) + ";";
		csv += await lastValueFrom(this._translateHelper.translate("t_device")) + ";";
		csv += await lastValueFrom(this._translateHelper.translate("t_serial")) + ";";
		csv += await lastValueFrom(this._translateHelper.translate("t_menu")) + ";";
		csv += await lastValueFrom(this._translateHelper.translate("result.48")) + "\n";

		for (const meas of measurements) {
			csv += formatDate(meas.timeStamp, await lastValueFrom(this._translateHelper.translate('t_time_format')), this.locale) + ";";
			csv += await lastValueFrom(this._translateHelper.translate("device." + meas.device)) + ";";
			csv += await lastValueFrom(this._translateHelper.translate(meas.completeSerial)) + ";";
			csv += await lastValueFrom(this._translateHelper.translate("menu." + meas.menuId)) + ";";

			const cardPoints = this._cardService
				.getCardConfiguration()
				.get(meas.menuId);

			if (cardPoints == undefined)
				csv += "\n";
			else {
				for (const cardPoint of cardPoints) {
					if (cardPoint.dataType == 4) {
						let value = meas.translateResults.filter(
							(tmp) =>
								tmp.phaseId == cardPoint.phaseID &&
								tmp.resultId == cardPoint.resultID
						);

						if (value !== undefined && value.length != 0)
							csv += await lastValueFrom(this._translateHelper.translate("evaluation." + value[0].resultValue));

						csv += "\n";
					}
				}
			}
		}

		const file = new Blob([csv], {
			type: "text/csv",
		});
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(file);

		link.download = await lastValueFrom(this._translateHelper.translate("t_measurements")) + '.csv';
		link.click();
	}

	/**
	 * @brief Exportiert die aktuelle Ansicht.
	 */
	public multiExport() {
		if (!this.downloading.value) {
			this.downloading.next(true);

			let startDateModified = false;
			let endDateModified = false;

			if (this.detailSearch.value.startDate == null) {
				this.detailSearch.value.startDate = "01.01.1990";
				startDateModified = true;
			}
			if (this.detailSearch.value.endDate == null) {
				this.detailSearch.value.endDate = new Date().toDateString();
				endDateModified = true;
			}

			if (this.detailSearch.value.menuId == null) {
				this.detailSearch.value.menuId = '-1';
			}

			switch (this._pageType) {
				case Page.MEAS_LIST:
					{
						this._downloadManager.downloadMeasurements(this.page.value, this.pageSize.value, this._translate.currentLang, this.detailSearch.value).then(_ => {
							this.downloading.next(false);
						})
							.catch(res => {
								this.downloading.next(false);
								this._errorHandlerService.handleHTTPError(res);
							});
					} break;

				case Page.DEVICE_TEST:
					{
						this._downloadManager.downloadDevicetests(this.page.value, this.pageSize.value, this._translate.currentLang, this.detailSearch.value).then(_ => {
							this.downloading.next(false);
						})
							.catch(res => {
								this.downloading.next(false);
								this._errorHandlerService.handleHTTPError(res);
							});
					} break;

				default: this.downloading.next(false); break;
			}

			if (startDateModified)
				this.detailSearch.value.startDate = null;

			if (endDateModified)
				this.detailSearch.value.endDate = null;
		}
	}

	public downloadCollectionReport() {
		if (!this.downloading.value) {
			this.downloading.next(true);

			let startDateModified = false;
			let endDateModified = false;

			if (this.detailSearch.value.startDate == null) {
				this.detailSearch.value.startDate = "01.01.1990";
				startDateModified = true;
			}
			if (this.detailSearch.value.endDate == null) {
				this.detailSearch.value.endDate = new Date().toDateString();
				endDateModified = true;
			}

			if (startDateModified)
				this.detailSearch.value.startDate = null;

			if (endDateModified)
				this.detailSearch.value.endDate = null;

			this._multiReport.getMultiFileReport(this.page.value, this.pageSize.value, this._translate.currentLang, this.detailSearch.value, "response", undefined, { httpHeaderAccept: <any>"application/pdf" }).pipe(
				this._rxjs.catchHttpErrors(
					finalize(() => this.downloading.next(false))
				),
				take(1)
			).subscribe(pdf => {
				const file = pdf.body as Blob;

				// TODO: Mal einen schönen Helper dafür schreiben.
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(file);
				const contentDisposition = pdf.headers.get('content-disposition');
				const pdffilename = contentDisposition.split(';')[1].split('filename')[1].
					split('=')[1].replace('\"', '').replace('\"', '');
				link.download = pdffilename + '.pdf';
				link.click();

				this._analyticsService.trackEvent(new TrackEventDownload({
					name: pdffilename + '.pdf',
					action: 'measmulti-pdf'
				}));
			});

			// 	this._http.post(this.env.connectGwUri + "/multireport/" + this.page.value + "/100/" + this._translate.currentLang, this.detailSearch.value,
			// 		{
			// 			// params: httpParams,
			// 			// headers: httpHeaders,
			// 			headers: {
			// 				"Authorization": "Bearer " + this._authService.getTokenValue()
			// 			},
			// 			observe: 'response',
			// 			responseType: 'blob' as 'json',
			// 		})
			// 		.subscribe((response) => {
			// 			const file = new Blob([response.body as 'blob'], {
			// 				type: "application/pdf",
			// 			});

			// 			const link = document.createElement("a");
			// 			link.href = window.URL.createObjectURL(file);
			// 			const contentDisposition = response.headers.get('content-disposition');
			// 			const pdffilename = contentDisposition.split(';')[1].split('filename')[1].
			// 				split('=')[1].replace('\"', '').replace('\"', '');
			// 			link.download = pdffilename + '.pdf';
			// 			link.click();
			// 			this.downloading.next(false);
			// 		},
			// 		_ => {
			// 			this.downloading.next(false);
			// 		});
		}
	}
}
