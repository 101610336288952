import { Injectable } from '@angular/core';

// In diesem enum befinden sich die Namen für den session storage.
// Sollten neue Seiten hinzukommen, können wir diese hier ergänzen.
export enum Page {
	MEAS_LIST = "MEAS_LIST",
	DEVICE_TEST = "DEVICE_TEST",
	ORDER_LIST = "ORDER_LIST",
	SERVICE_LIST = "SERVICE_LIST"
};

@Injectable({
	providedIn: 'root'
})
export class FilterStorageService {
	constructor() { }

	public clearFilter(page: Page) {
		sessionStorage.removeItem(page);
	}

	/**
	 * Speichert einen filter im storage.
	 */
	public saveFilter(filter: any, page: Page): void {
		sessionStorage.setItem(page, JSON.stringify(filter));
	}

	/**
	 * Lädt den filter aus dem storage oder erstellt einen neuen.
	 */
	public loadFilter<Type>(page: Page): Type {
		var data = sessionStorage.getItem(page);
		if(!data)
			data = "{}"

		let Ret: Type = <Type>JSON.parse(data);

		if (Ret == null)
			Ret = {} as Type;

		return Ret;
	}
}
