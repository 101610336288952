import { Injectable, EventEmitter } from "@angular/core";
import { environment } from "src/environments/environment";
import { AvailableSearchParameter, FilterService } from "@esders/connect-api";


@Injectable({
	providedIn: "root",
})
export class CompareService {
	// tslint:disable-next-line: no-inferrable-types
	env = environment;

	private endpoint: string = "";

	// Zum binden
	public ASP: AvailableSearchParameter[] = [];
	public onChange: EventEmitter<any> = new EventEmitter<any>();
	public isLoaded: boolean = false;

	constructor(
		private filterService: FilterService,
	) { }

	public GetSearchParamsFromServer(data: AvailableSearchParameter[]) {
		if (data != null) {
			data.forEach((element) => {
				if (element.measType === 1) {
					(element as any).translateField = "result." + element.field;
				} else {
					(element as any).translateField = element.field;
				}
			});
		}

		this.ASP = data;
		this.onChange.emit({});

		this.isLoaded = true;
	}

	public Refresh(endpoint: string) {
		this.endpoint = endpoint;
	}

	public GetCompareTypes(Type: number): string[] {
		// 0 = bool, 1 = string, 2 = int, 3 = double, 4 = translate
		let Comparers: string[] = [];

		switch (Type) {
			case 0: Comparers = ["=", "!="];
				break;
			case 1: Comparers = ["=", "!=", "=*"];
				break;
			case 2: Comparers = ["=", "!=", "<", ">", "<=", ">="];
				break;
			case 3: Comparers = ["=", "!=", "<", ">", "<=", ">="];
				break;
			case 4: Comparers = ["=", "!="];
				break;
		}

		return Comparers;
	}

	public AddSearchParameter(param: AvailableSearchParameter) {
		this.ASP.push(param);

		//.post(this.env.connectGwUri + "/filter/" + this.endpoint,param).subscribe();
		if(this.endpoint.toLocaleLowerCase() == "meas"){
			this.filterService.createMeasSearchParameter(param).subscribe();
		}
		else if(this.endpoint.toLocaleLowerCase() == "devicetest"){
			this.filterService.createDeviceTestSearchParameter(param).subscribe();
		}
	}

	DeleteSearchParameter(param: AvailableSearchParameter) {
		const index = this.ASP.findIndex((sn) => sn.measType === param.measType && sn.field == param.field);
		this.ASP.splice(index, 1);

		//.delete(this.env.connectGwUri + "/filter/" + this.endpoint + "/" + param.measType + "/" + param.dataType + "/" + param.field, {} ).subscribe();
		if(this.endpoint.toLocaleLowerCase() == "meas"){
			this.filterService.deleteMeasSearchParameter(param.measType, param.dataType, param.field).subscribe();
		}
		else if(this.endpoint.toLocaleLowerCase() == "devicetest"){
			this.filterService.deleteDeviceTestSearchParameter(param.measType, param.dataType, param.field).subscribe();
		}
	}
}
