import { Injectable } from '@angular/core';
import { FilterStorageService, Page } from './filterStorage.service';
import { DetailedSearchObject } from '@esders/connect-api';

@Injectable({
	providedIn: 'root'
})
export class DetailsearchService {
	private detailSearch: DetailedSearchObject = {} as DetailedSearchObject;
	private currentPage: Page | null = null;

	constructor(
		private filterStorage: FilterStorageService) { }

	/**
	 * Lädt den gespeicherten filter.
	 * @param page Seite dessen filter geladen werden soll.
	 */
	public getDetailedSearch(page: Page): DetailedSearchObject {
		this.detailSearch = this.filterStorage.loadFilter(page);
		this.detailSearch.archived = false;

		if (this.detailSearch.menuId == undefined)
			this.detailSearch.menuId = "-1";

		if (this.detailSearch.details == undefined)
			this.detailSearch.details = [];

		this.currentPage = page;
		return this.detailSearch;
	}

	/**
	 * @brief Löscht den Filter.
	 */
	public setFilter(detailSearch: DetailedSearchObject) {
		if(this.currentPage) {
			this.detailSearch = detailSearch;
			this.filterStorage.clearFilter(this.currentPage);
		}
	}

	/**
	 * Speichert den letzten filter.
	 */
	public saveFilter(): void {
		if (this.detailSearch != null && this.currentPage) {
			this.filterStorage.saveFilter(this.detailSearch, this.currentPage);
		}
	}
}
